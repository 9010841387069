<ng-container *ngIf="appConfig.currentEntity || isMobile">
  <div
    class="sbx-menu-overlay"
    (click)="close()"
    *ngIf="sbxSideMenuService.open || menuItemSelected"
  ></div>

  <div class="sbx-menu-left sbx-desktop">
    <ng-container
      *ngTemplateOutlet="menuItemsTemplate; context: { $implicit: topMenuItems }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="menuItemsTemplate; context: { $implicit: bottomMenuItems }"
    ></ng-container>
  </div>

  <div class="sbx-menu-right sbx-desktop" [ngClass]="{ 'sbx-open': menuItemSelected }">
    <div class="sbx-title-container" *ngIf="menuItemSelected">
      <div class="sbx-menu-title">
        {{ menuItemSelected.title }}
      </div>
      <ng-container *ngTemplateOutlet="menuCloseButtonTemplate"></ng-container>
    </div>

    <sbx-scrollable class="sbx-scrollable">
      <ng-container *ngIf="menuItemSelected">
        <ng-container *ngTemplateOutlet="menuItemSectionsTemplate"></ng-container>
      </ng-container>
    </sbx-scrollable>
  </div>

  <div
    class="sbx-menu-right sbx-mobile"
    [ngClass]="{ 'sbx-open': sbxSideMenuService.open }"
  >
    <ng-container *ngTemplateOutlet="mobileHeaderTemplate"></ng-container>

    <div class="sbx-title-container" *ngIf="menuItemSelected">
      <div class="sbx-menu-title">
        {{ menuItemSelected.title }}
      </div>
      <sbx-icon
        type="arrowLeftCircleAlt"
        class="sbx-menu-close-icon"
        (click)="menuItemSelected = undefined"
      ></sbx-icon>
    </div>

    <sbx-scrollable class="sbx-scrollable">
      <ng-container *ngIf="!menuItemSelected">
        <ng-container
          *ngTemplateOutlet="menuItemsTemplate; context: { $implicit: topMenuItems }"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="menuItemsTemplate; context: { $implicit: bottomMenuItems }"
        ></ng-container>
      </ng-container>
      <ng-container *ngIf="menuItemSelected">
        <ng-container *ngTemplateOutlet="menuItemSectionsTemplate"></ng-container>
      </ng-container>
    </sbx-scrollable>

    <ng-container *ngIf="!menuItemSelected">
      <ng-container *ngTemplateOutlet="mobileFooterTemplate"></ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #menuItemsTemplate let-items>
  <div class="sbx-menu-items" [ngClass]="{ 'sbx-has-items': items.length }">
    <ng-container *ngFor="let item of items">
      <ng-container
        *ngTemplateOutlet="menuItemTemplate; context: { $implicit: item }"
      ></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #menuItemTemplate let-item>
  <sbx-popover
    [sbxPopoverContent]="item.title"
    [attr.data-menu-id]="item.id"
    sbxPopoverPlacement="right"
  >
    <div class="sbx-menu-item" (click)="handleItemClick(item)">
      <sbx-icon
        [type]="item.icon"
        [ngClass]="{
          'sbx-menu-item-icon': true,
          'sbx-active': menuItemSelected?.id === item.id
        }"
      ></sbx-icon>
      <span class="sbx-menu-item-title">{{ item.title }}</span>
    </div>
  </sbx-popover>
</ng-template>

<ng-template #menuItemSectionsTemplate>
  <ng-container *ngFor="let section of menuItemSelected.sections">
    <div class="sbx-section" *ngIf="section.items.length > 0">
      <div class="sbx-menu-subtitle" *ngIf="section.title">{{ section.title }}</div>

      <ng-container *ngFor="let item of section.items">
        <a
          *ngIf="item.link"
          class="sbx-menu-subitem"
          [sbxRouterLink]="item.link"
          [target]="item.target || '_self'"
          (click)="close()"
        >
          {{ item.title }}
        </a>

        <ng-container *ngIf="$any(item).click">
          <span class="sbx-menu-subitem" (click)="$any(item).click()">
            {{ item.title }}
          </span>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #menuCloseButtonTemplate>
  <sbx-icon type="close" class="sbx-menu-close-icon" (click)="close()"></sbx-icon>
</ng-template>

<ng-template #mobileHeaderTemplate>
  <div class="sbx-mobile-header">
    <div class="sbx-mobile-first-name" *ngIf="appConfig.userInfo">
      Hello {{ appConfig.userProfile.firstName }}!
    </div>
    <div class="sbx-mobile-entity-name" *ngIf="appConfig.currentEntity">
      <span>{{ appConfig.currentEntity.title }}</span>
    </div>
    <div
      *ngIf="myOtherCompaniesMenuItem && !menuItemSelected"
      class="sbx-mobile-my-other-companies"
    >
      <ng-container
        *ngTemplateOutlet="
          menuItemTemplate;
          context: { $implicit: myOtherCompaniesMenuItem }
        "
      ></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="menuCloseButtonTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #mobileFooterTemplate>
  <div class="sbx-mobile-footer">
    <div class="sbx-mobile-footer-copyright">
      ©2014-2025 FMR LLC. All rights reserved.
    </div>
    <div class="sbx-mobile-footer-logout" *ngIf="appConfig.userInfo">
      <sbx-button theme="secondary" title="Sign out" (click)="logout()"></sbx-button>
    </div>
  </div>
</ng-template>
