<sbx-container [fluid]="true">
  <sbx-row>
    <sbx-col [lg]="12" class="sbx-flex">
      <span class="sbx-white-text">©2014-2025 FMR LLC. All rights reserved.</span>

      <ul>
        <li>
          <a href="https://www.fidelityprivateshares.com" target="_blank"
            >About Fidelity Private Shares</a
          >
        </li>
        <li>
          <a href="/privacy-and-terms.html" target="_blank">Privacy & Terms</a>
        </li>
        <li>
          <a href="https://support.shoobx.com">Support</a>
        </li>
      </ul>

      <span class="sbx-version sbx-white-text" *ngIf="version">
        Version: {{ version }}
      </span>
    </sbx-col>
  </sbx-row>
</sbx-container>
