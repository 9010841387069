import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Downgrade } from '../downgrade';

const SELECTOR: string = 'sbx-radio-group';

@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-radio-group.component.html',
  styleUrls: ['sbx-radio-group.component.scss'],
})
export class SbxRadioGroupComponent {
  @Input() public name = null;
  @Input() public items = [];
  @Input() public model = null;
  @Input() public layout: 'horizontal' | 'vertical' = 'horizontal';
  @Input() public disabled: boolean = false;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public focus = new EventEmitter();
  @Output() public modelChange = new EventEmitter();

  public handleFocus(event): void {
    this.focus.emit(event);
  }

  public handleChange(value): void {
    this.modelChange.emit(value);
  }
}
