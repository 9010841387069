import angular from 'angular';
import { List, Map } from 'immutable';

/**
 * @ngdoc service
 * @kind function
 * @name sb.lib.form.ListFunctionality
 *
 * @description
 * This is a small factory function to augment an `sbList` directive
 * with some base functionality.
 *
 * @param {object} scope The scope of the directive.
 * @param {object} tScope The trancluded scope of the list's content.
 * @param {object} ngModelCtrl The model controller of the list
 */
export function ListFunctionality() {
  return function (scope, tScope, ngModelCtrl, parser, formatter) {
    function makeRow(idx, value) {
      const row = {
        id: idx,
        value: formatter(value),
        desc: angular.copy(scope.rowDesc()),
      };
      if (row.desc) {
        // A separate form control per row
        row.desc.id = row.desc.id + '_' + idx;
      }
      row.$watcher = tScope.$watch(
        () => row.value,
        () => {
          // trigger update on view value change so it cascades to scope.model
          ngModelCtrl.$setViewValue(ngModelCtrl.$viewValue.map((val) => val));
        },
        true,
      );
      return row;
    }

    function addRow(newValue) {
      const rows = ngModelCtrl.$viewValue || [];

      // Will either be 0 (rows.length) or a max of IDs plus one.
      const newId =
        rows.length &&
        rows.reduce((curMax, { id }) => {
          return id > curMax ? id : curMax;
        }, rows[0].id) + 1;

      const row = makeRow(newId, newValue);
      rows.push(row);
      ngModelCtrl.$setViewValue(rows);
    }

    function editRow(index, newValue) {
      ngModelCtrl.$viewValue[index].value = formatter(newValue);
    }

    function removeRow(index) {
      const rows = ngModelCtrl.$viewValue;
      rows[index].$watcher();

      ngModelCtrl.$setViewValue(rows.filter((row, rowIdx) => rowIdx !== index));
    }
    function makeViewValue(modelValues) {
      modelValues = modelValues || [];
      return modelValues.map((value, i) => {
        return makeRow(i++, value);
      });
    }

    tScope.$modelCtrl = ngModelCtrl;
    tScope.$removeRow = removeRow;
    tScope.$readOnly = scope.readOnly;
    parser = parser || angular.identity;
    formatter = formatter || angular.identity;

    scope.addRow = addRow;
    scope.editRow = editRow;
    scope.removeRow = removeRow;

    ngModelCtrl.$render = angular.noop;
    ngModelCtrl.$parsers.push((viewValues) => {
      viewValues = viewValues || [];
      return viewValues.map((row) => parser(row.value));
    });
    ngModelCtrl.$formatters.push((newValues) => {
      ngModelCtrl.$viewValue.forEach((row) => row.$watcher());
      return makeViewValue(newValues);
    });

    ngModelCtrl.$setViewValue(makeViewValue(scope.model));
  };
} // end ListFunctionality

/**
 * @ngdoc directive
 * @name sb.lib.form.directive:sbList
 * @restrict EA
 * @requires https://docs.angularjs.org/api/ng/type/ngModel.NgModelController
 *
 * @description
 * This directive is intended for models of array/list type. Its transcluded
 * and the internal scope is augmented with `$modelCtrl`. This is the
 * ngModel controller object and one can iterate on `$modelCtrl.$viewValue`.
 * This list comprises of a list of objects in the form of `{ id: <rowId>,
 * value: <rowData> }`. The value property is intended for the user to use for
 * sub `ngModel` directives. See example for a good starting reference.
 *
 * @element ANY
 * @param {expression} ngModel Model expression. Model will be an array of
 *    values.
 * @param {array} sbListErrors Field error messages.
 * @param {function} [sbListRequired=undefined] If this is set to a function,
 *    this is the function that will be fired on each element to determine if it
 *    has met the $validator's standards. It is expected to return true or false
 * @param {object} [sbListRowDesc=undefined] optional field description from
 *    formly list descriptions
 * @param {boolean} [sbListReadOnly=false] If truthy, list will be uneditable.
 * @param {boolean} [noAddButton=undefined] If truthy, list will not display an add
 *   button.
 *
 * @example
   <!--
   $scope.required = function(item) {
     // Each item must have a length
     return item.value.length > 0;
   };
   -->

   <sb-list
     data-ng-model="model"
     data-sb-list-errors="fieldErrors()"
     data-sb-list-required="required">

     <div class="iterable-row row"
       data-ng-repeat="item in $modelCtrl.$viewValue track by item.id">

       <input type="text" data-ng-model="item.value">
       <sb-list-remove></sb-list-remove>

     </div>

   </sb-list>
 */
export const sbList = [
  'ListFunctionality',
  function (ListFunctionality) {
    return {
      restrict: 'EA',
      template: require('./templates/widgets/list.html'),
      transclude: true,
      require: 'ngModel',
      scope: {
        noAddButton: '<?',
        model: '=ngModel',
        required: '&sbListRequired',
        errors: '&sbListErrors',
        rowDesc: '&sbListRowDesc',
        readOnly: '<?sbListReadOnly',
      },
      link: function (scope, element, attrs, ngModelCtrl, transclude) {
        const valueKey = attrs.sbListOptionKey;
        ngModelCtrl.$validators.required = function (modelValue) {
          let requiredFunc = scope.required();
          if (!requiredFunc) {
            return true;
          } else if (!angular.isArray(modelValue) || modelValue.length === 0) {
            return false;
          } else if (!angular.isFunction(requiredFunc)) {
            requiredFunc = (i) => !ngModelCtrl.$isEmpty(i);
          }
          return modelValue.reduce((accum, item) => {
            return accum && requiredFunc(item);
          }, true);
        };
        function parseViewValue(value) {
          return value && valueKey ? value[valueKey] : value;
        }
        function formatModelValue(value) {
          if (valueKey) {
            const object = {};
            object[valueKey] = value;
            return object;
          }
          return value;
        }

        transclude((tElem, tScope) => {
          element.find('.list-content').html(tElem);
          ListFunctionality(
            scope,
            tScope,
            ngModelCtrl,
            parseViewValue,
            formatModelValue,
          );
        });
      },
    };
  },
]; // end sbList

/**
 * @ngdoc directive
 * @name sb.lib.form.directive:sbFormsList
 * @restrict EA
 *
 * @description
 * This directive is intended for models of array/list type. It is similar to
 * {@link sb.lib.form.directive:sbList sbList} but uses modals for add/edit
 * functionality. It uses transclusion to augment paint rows. See `sbList` for
 * full documentation. In addition, this directive adds `$editRow` for edit
 * functionality.
 *
 * @element ANY
 * @param {expression} ngModel Model expression. Model will be an array of
 *    values.
 * @param {template} sbFormsListModalTemplate A template url string for the
 *    edit/add modal.
 * @param {object} sbFormsListModalData This is an object mapping that the modal
 *    will extend its scope with. These values may also be promises that resolve
 *    with data. If a string is specified as the value, then that injected
 *    service is called and the return value (promise or otherwise) is used.
 * @param {boolean} [sbFormsListRequired=undefined] If truthy, the list will
 *    have a length required validation on it.
 * @param {array} sbFormsListErrors Field error messages.
 * @param {object} [sbListRowDesc=undefined] optional field description from
 *    formly list descriptions
 *
 * @example
   <sb-forms-list
     data-ng-model="model.list"
     data-sb-forms-list-required="required"
     data-sb-forms-list-modal-template="modal.html"
     data-sb-forms-list-modal-data="modalData">

     <div class="iterable-row row"
       data-ng-repeat="item in $modelCtrl.$viewValue track by item.id">
       <div data-ng-bind="item.value"></div>
       <sb-list-edit></sb-list-edit>
       <sb-list-remove></sb-list-remove>
     </div>

   </sb-forms-list>
 */
export const sbFormsList = [
  'ListFunctionality',
  '$sbModal',
  '$q',
  '$injector',
  'ProcessStatus',
  'PromiseErrorCatcher',
  function (
    ListFunctionality,
    $sbModal,
    $q,
    $injector,
    ProcessStatus,
    PromiseErrorCatcher,
  ) {
    return {
      restrict: 'EA',
      require: 'ngModel',
      transclude: 'true',
      template: () => {
        const elm = angular.element(
          `<div>${require('./templates/widgets/list.html')}</div>`,
        );
        elm.find('.iter-widget-add-row').append(`
        <i style="margin-left: 10px;top: 4px;"
           class="fas fa-exclamation-triangle"
           sb-error-tooltip="errors()">
        </i>
      `);
        return elm.html();
      },
      scope: {
        model: '=ngModel',
        required: '&sbFormsListRequired',
        modalTemplate: '@sbFormsListModalTemplate',
        modalData: '&sbFormsListModalData',
        errors: '&sbFormsListErrors',
        rowDesc: '&sbListRowDesc',
      },
      link: function (scope, element, attrs, ngModelCtrl, transclude) {
        function popupModal(row) {
          const valueMapping = {};
          const modalData = scope.modalData() || {};
          let calls, service, valueGetter;
          const items = Object.keys(modalData).map((key) => {
              const item = modalData[key];
              valueGetter = undefined;
              if (angular.isString(item)) {
                calls = item.split('.');
                try {
                  service = $injector.get(calls[0]);
                } catch (err) {
                  valueGetter = $q.when(item);
                }
                if (angular.isUndefined(valueGetter) && calls.length === 1) {
                  valueGetter = $q.when(service);
                } else if (angular.isUndefined(valueGetter)) {
                  let func = service;
                  for (let i = 1; i < calls.length; i++) {
                    func = func[calls[i]];
                  }
                  valueGetter = $q.when(func.call(service));
                }
              } else {
                valueGetter = $q.when(item);
              }

              return valueGetter.then((value) => {
                valueMapping[key] = value;
                return value;
              });
            }),
            modal = $sbModal.open({
              size: 'md',
              templateUrl: scope.modalTemplate,
              controller: 'FormsListModalController',
              resolve: {
                scopeMapping: () => $q.all(items).then(() => valueMapping),
                row: () => angular.copy(row),
              },
            });

          modal.opened.catch(() => {
            ProcessStatus.$setStatus(
              "Can't open modal, please contact support.",
              'danger',
            );
          });

          return modal.result;
        } // end popupModal()

        const required = scope.required();
        if (required) {
          ngModelCtrl.$validators.required = function (value) {
            return Boolean(value.length);
          };
        }
        transclude((transElement, transScope) => {
          element.find('.list-content').html(transElement);
          ListFunctionality(scope, transScope, ngModelCtrl);

          transScope.$editRow = function (index) {
            popupModal(scope.model[index])
              .then((row) => {
                scope.editRow(index, row);
              })
              .catch(PromiseErrorCatcher);
          };
          const addRowInner = scope.addRow;
          scope.addRow = function () {
            popupModal({})
              .then((row) => {
                addRowInner(row);
              })
              .catch(PromiseErrorCatcher);
          };
        });
      },
    };
  },
]; // end sbFormsList

/**
 * @ngdoc object
 * @name sb.lib.form.controller:FormsListModalController
 *
 * @description
 * Controller for the modal that a {@link sb.lib.form.directive:sbFormsList
 * sbFormsList} pops up during an edit/add of row.
 */
export const FormsListModalController = [
  '$scope',
  '$q',
  'Stakeholders',
  'FormSubmitResolve',
  'scopeMapping',
  'row',
  function ($scope, $q, Stakeholders, FormSubmitResolve, scopeMapping, row) {
    const resolver = FormSubmitResolve($scope);

    function createStakeholers() {
      const { stakeholder } = $scope.$row;
      if (!stakeholder) {
        return $q.resolve();
      }
      let { type } = stakeholder;
      if (!type || !type.startsWith('new')) {
        return $q.resolve();
      }
      type = stakeholder.type.toLowerCase().replace('new', '');
      const createData = { description: stakeholder.sh, type };
      return Stakeholders.create(createData).then((newSh) => {
        $scope.$row.stakeholder = {
          sh: newSh,
          type: 'existingPerson',
        };
      });
    }
    function save() {
      $scope.$error = null;
      resolver.resolve($scope.formFeedback).then(
        () => {
          $scope.$close($scope.$row);
        },
        () => {
          $scope.$error = 'Could not save, please try again.';
        },
      );
    }

    angular.extend($scope, scopeMapping);
    $scope.$row = row;
    $scope.save = save;

    // TODO this needs to be removed. Converters need to be able to handle undcreated
    // stakeholders.
    $scope.$emit('registerFormSubmitPromise', createStakeholers);
    $scope.$on('destroy', () => {
      $scope.$emit('deregisterFormSubmitPromise', createStakeholers);
    });
  },
]; // end FormsListModalController

/**
 * @ngdoc service
 * @kind function
 * @name sb.lib.form.ListButtonFactory
 *
 * @description
 * This is a small factory function to produce a list button. See `sbListEdit`
 * or `sbListRemove`.
 *
 * @param {string} funcName Name of the function to call on click action.
 * @param {string} icon Icon to use for the button.
 *
 * @returns {object} A directive defintion for a sbList button.
 */
export function ListButtonFactory() {
  return function (funcName, icon) {
    return {
      restrict: 'E',
      scope: true,
      template: require('./templates/widgets/list-button.html'),
      link: function (scope) {
        scope.action = scope[funcName];
        scope.icon = icon;
      },
    };
  };
} // end ListButtonFactory

/**
 * @ngdoc directive
 * @name sb.lib.form.directive:sbListEdit
 * @restrict EA
 *
 * @description
 * This directive is intended for use with {@link sb.lib.form.directive:sbList
 * sbList} or {@link sb.lib.form.directive:sbFormsList sbFormsList}. It draws a
 * edit row button. See the example in each respective directive.
 *
 * @element ANY
 */
export const sbListEdit = [
  'ListButtonFactory',
  function (ListButtonFactory) {
    return ListButtonFactory('$editRow', 'fa-edit');
  },
]; // end sbListEdit

/**
 * @ngdoc directive
 * @name sb.lib.form.directive:sbListRemove
 * @restrict EA
 *
 * @description
 * This directive is intended for use with {@link sb.lib.form.directive:sbList
 * sbList} or {@link sb.lib.form.directive:sbFormsList sbFormsList}. It draws a
 * remove row button. See the example in each respective directive.
 *
 * @element ANY
 */
export const sbListRemove = [
  'ListButtonFactory',
  function (ListButtonFactory) {
    return ListButtonFactory('$removeRow', 'fa-times-circle');
  },
]; // end sbListRemove

class sbIconDropCtrl {
  $onInit() {
    this.ngModel = this.ngModel || this.options[0].value;
    // Must set model first
    this.disabled = this.disabled || false; // make sure watcher ends
    this.ngModelCtrl.$parsers.push((item) => item.value);
    this.ngModelCtrl.$formatters.push(this.finditem.bind(this));
  }

  finditem(val) {
    const item = this.options.find((item) => item.value === val);
    if (val && angular.isUndefined(item)) {
      throw Error('Value not found in options');
    }
    return item;
  }
}

/**
 * @ngdoc component
 * @name sb.lib.form.component:sbIconDropDown
 *
 * @description
 * Displays a list of labels with icons in a drop down.
 * Current selection is displayed in button content along with an icon.
 *
 * @param {expression} ngModel Model expression. If model is null, the
 * first option will be selected. Otherwise the value provided with be used.
 * @param {array} options A list of objects that each contain label, icon,
 * and value.
 *
 * @example
 <sb-icon-drop-down
    options="[
    { value: 'shoobx', label: 'Shoobx', icon: 'fa-user' },
    { value: 'shoobix', label: 'Shoobix', icon: 'fa-university' },
    ]"
    ng-model="test"></sb-icon-drop-down>`
 */
export const sbIconDropDown = {
  controllerAs: 'vm',
  template: require('./templates/widgets/icon-drop-down.html'),
  require: {
    ngModelCtrl: 'ngModel',
  },
  bindings: {
    options: '<',
    ngModel: '=',
    disabled: '<?',
  },
  controller: sbIconDropCtrl,
};

/**
 * @ngdoc component
 * @name sb.lib.form.component:sbListOfStakeholder
 *
 * @description
 * Displays a sbList with some specialization for stakeholders.
 *
 * @param {expression} ngModel Model expression.
 * @param {Object} stakeholderFieldConfig stakeholder chooser configuration object.
 * @param {array} [errors=undefined] list of error strings
 * @param {Object} [listOptions=undefined] user io config for a list
 *
 */
export const sbListOfStakeholder = {
  controllerAs: 'vm',
  template: require('./templates/widgets/list-of-sh.html'),
  require: {
    ngModelCtrl: 'ngModel',
  },
  bindings: {
    model: '=ngModel',
    shConfig: '<stakeholderFieldConfig',
    errors: '<',
    options: '<listOptions',
  },
  controller: [
    function () {
      this.$onInit = () => {
        this.isNotSelected = ({ id }) =>
          !this.model.filter(angular.isObject).some((sh) => sh.sh.id === id);
        this.ngModelCtrl.$overrideModelOptions({ allowInvalid: true });
      };

      this.$postLink = () => {
        this.ngModelCtrl.$validators.required = (modelValue) => {
          // never validate when required is off
          if (!this.options.templateOptions.required) {
            return true;
          }
          if (!modelValue) {
            return false;
          }
          return modelValue[0];
        };
      };

      this.addItem = (stakeholderObject) => {
        if (
          stakeholderObject.sh.id &&
          this.isNotSelected({ id: stakeholderObject.sh.id })
        ) {
          this.model = [...this.model, stakeholderObject];
        }
      };
    },
  ],
};

/**
 * @ngdoc component
 * @name sb.lib.form.component:sbListOfStockTicker
 *
 * @description
 * Displays a sbList of stock tickers
 *
 * @param {expression} ngModel Model expression.
 *
 */
export const sbListOfStockTicker = {
  controllerAs: 'vm',
  template: require('./templates/widgets/list-of-stock-ticker.html'),
  require: {
    ngModelCtrl: 'ngModel',
  },
  bindings: {
    model: '=ngModel',
  },
};

/**
 * @ngdoc component
 * @name sb.lib.form.component:sbListOfDocument
 *
 * @description
 * Displays a sbList with some specialization for documents.
 *
 * @param {expression} ngModel Model expression.
 * @param {array} [errors=undefined] list of error strings
 * @param {Object} [listOptions=undefined] user io config for a list
 *
 */
export const sbListOfDocument = {
  controllerAs: 'vm',
  template: require('./templates/widgets/list-of-doc.html'),
  require: {
    ngModelCtrl: 'ngModel',
  },
  bindings: {
    model: '=ngModel',
    errors: '<',
    options: '<listOptions',
  },
  controller: [
    '$scope',
    function ($scope) {
      this.$onInit = () => {
        this.isNotSelected = ({ id }) =>
          !this.model.some((doc) => doc && id === doc.documentId);
      };

      // remove any cancelled uploads (except last row)
      const clearEmpty = (docs) => {
        const lastIndex = docs.length - 1;
        return docs.filter(
          (doc, index) => index >= lastIndex || doc === null || doc.documentId,
        );
      };

      // add a new row only if last doc has been uploaded
      const addRow = (docs) => {
        const last = docs[docs.length - 1];
        return last && last.documentId ? docs.concat([null]) : docs;
      };

      function chainWithChecks(arr, ...ops) {
        return ops.reduce(
          ({ changed, acc }, op) => {
            const ret = op(acc);
            return { changed: changed || ret.length !== acc.length, acc: ret };
          },
          { changed: false, acc: arr },
        );
      }

      $scope.$watch('vm.model', (newValue) => {
        if (!newValue || newValue.length <= 0) {
          this.model = [null];
          return;
        }
        const { changed, acc } = chainWithChecks(newValue, clearEmpty, addRow);
        if (changed) {
          this.model = acc;
        }
      });

      this.$postLink = () => {
        this.ngModelCtrl.$validators.required = (modelValue) => {
          // never validate when required is off
          if (!this.options.templateOptions.required) {
            return true;
          }
          if (!modelValue) {
            return false;
          }
          return modelValue[0] && modelValue[0].documentId;
        };
      };
    },
  ],
};

/**
 * @ngdoc filter
 * @kind function
 * @name sb.lib.form.filter:tableFmt
 *
 * @description
 * Summarized table formatting for formly values.
 *
 * contract: Field-types which have a well-defined formatting must
 * return a non-null value. All non-well-defined formats will always
 * return null.
 *
 * @param {string} type of formly-config field.
 * @param {object} value of the field.
 *
 */
export const tableFmt = [
  '$filter',
  function ($filter) {
    return (value, type) => {
      switch (type) {
        case 'read-only':
        case 'address':
        case 'text':
        case 'enum-dropdown':
        case 'enum-radios':
        case 'reference-radios':
        case 'email-textline':
        case 'require-verification':
        case 'string-textline':
        case 'department':
        case 'string-typeahead-textline':
        case 'object-typeahead-textline':
        case 'date':
        case 'time':
        case 'exp-date':
          return value || '-';
        case 'bool-checkbox':
          return value ? 'Yes' : 'No';
        case 'number-textline':
        case 'measurement-textline':
          return value ? $filter('number')(value) : '-';
        case 'stakeholder':
          return value ? value.sh.fullName : '-';
        case 'document-reference':
          return value ? value.documentTitle : '-';
        case 'record-table':
        case 'list-of-stakeholder':
        case 'list-of-document':
        case 'dict-table':
          throw new Error(`Impossible formly type for sbRecordTable: ${type}`);
        case 'bool-radios':
        case 'bool-toggle':
        case 'dictionary':
        case 'list':
        case 'checklist':
        case 'stripe-element':
        case 'pdf-file':
        default:
          // eslint-disable-next-line no-console
          console.warn(`Table format for ${type} type unimplemented`);
          return null;
      }
    };
  },
];

/**
 * @ngdoc component
 * @name sb.lib.form.component:sbRecordTable
 *
 * @description
 * Displays a sbList with some specialization for documents.
 *
 * @param {expression} ngModel Model expression.
 * @param {array} [errors=undefined] list of error strings
 * @param {Object} [listOptions=undefined] user io config for a list
 *
 */
export const sbRecordTable = {
  controllerAs: 'vm',
  template: require('./templates/widgets/record-table.html'),
  bindings: {
    model: '=ngModel',
    errors: '<',
    options: '<listOptions',
  },
  controller: [
    '$q',
    '$filter',
    class {
      constructor($q, $filter) {
        this.$q = $q;
        this.$filter = $filter;
        this._model = new List();
        this._schema = [];
        this.columns = null;
        this.init = false;
        this.addTitle = 'Add';
      }
      $onInit() {
        const { $q, $filter } = this;
        const toItems = (records) => {
          return List(
            records.map((record, index) => {
              return Map({
                id: index,
                deletable: this.options.templateOptions.canDelete ?? true,
                editable: true,
                editTitle: this.editTitle,
                index,
                data: Map(record).map((value, key) => {
                  const { type } = this.schema.get(key);
                  return new Map({
                    value,
                    fmtValue: $filter('tableFmt')(value, type),
                  });
                }),
              });
            }),
          );
        };

        const updater =
          (fn) =>
          (...args) => {
            fn(...args);
            this._model.$items = toItems(this.model);
            return $q.resolve(true);
          };

        const mkModel = (records) => ({
          $items: toItems(records),
          $hasEditOrDelete: true,
          $add: updater((data) => {
            this.model.push(data);
          }),
          $remove: updater((index) => {
            this.model.splice(index, 1);
          }),
          $edit: updater((index, data) => {
            this.model[index] = data;
          }),
          $editPromise: (_, modalParams) => {
            modalParams.formData = new Map(modalParams.formData)
              .map((f) => f.value)
              .toJS();
            return $q.resolve(modalParams);
          },
          $loading: false,
        });

        const toColumns = (fields, summary = {}) =>
          fields
            // Add to column only if a formatting is defined
            .filter(({ type }) => $filter('tableFmt')(null, type))
            .filter((field) => summary.has(field.key) || !summary.size)
            .map(({ key, templateOptions: { label } }) => ({
              key,
              name: summary[key] || label,
            }));

        const to = this.options.templateOptions;
        const { valueSchema, summaryFields } = to.valueType.templateOptions;
        valueSchema.forEach((field) => {
          if (field.templateOptions.providesFormContext) {
            field.templateOptions = {
              ...field.templateOptions,
              additionalContext: this.options.additionalContext,
            };
          }
        });
        this.addable = to.canAdd ?? true;
        this.title = to.label;
        this.schema = new Map(valueSchema.map((f) => [f.key, f]));
        this._model = mkModel(this.model);
        this._schema = { fields: valueSchema };
        this.columns = toColumns(valueSchema, new Map(summaryFields));
        if (to.valueLabel) {
          this.editTitle = `Edit ${to.valueLabel}`;
          this.addTitle = `Add ${to.valueLabel}`;
        } else {
          this.editTitle = `Edit item for ${this.title}`;
          this.addTitle = 'Add';
        }
        this.init = true;
      }
    },
  ],
};

/**
 * @ngdoc component
 * @name sb.lib.form.component:sbDictTable
 *
 * @description
 * Displays a sbList with some specialization for documents.
 *
 * @param {expression} ngModel Model expression.
 * @param {array} [errors=undefined] list of error strings
 * @param {Object} [listOptions=undefined] user io config for a list
 *
 */
export const sbDictTable = {
  controllerAs: 'vm',
  template: require('./templates/widgets/dict-table.html'),
  bindings: {
    model: '=ngModel',
    errors: '<',
    options: '<dictOptions',
  },
  controller: [
    '$q',
    '$filter',
    class {
      constructor($q, $filter) {
        this.$q = $q;
        this.$filter = $filter;
        this._model = new List();
        this._schema = [];
        this.columns = null;
        this.init = false;
        this.addTitle = 'Add';
      }
      $onInit() {
        const { $q, $filter } = this;
        const toItems = (data) => {
          return List(
            data.map(({ key, value }) => {
              return Map({
                id: key,
                deletable: false,
                editable: true,
                editTitle: this.editTitle,
                key: key,
                data: Map(value).map((rvalue, rkey) => {
                  const { type, templateOptions } = this.schema.get(rkey);
                  const { enumVocab } = templateOptions;
                  const fmtValue = enumVocab
                    ? enumVocab.find((item) => item.value === rvalue).label
                    : rvalue;
                  return new Map({
                    value: rvalue,
                    fmtValue: $filter('tableFmt')(fmtValue, type),
                  });
                }),
              });
            }),
          );
        };

        const updater =
          (fn) =>
          (...args) => {
            fn(...args);
            this._model.$items = toItems(this.model);
            return $q.resolve(true);
          };

        const mkModel = (records) => ({
          $items: toItems(records),
          $hasEditOrDelete: true,
          $edit: updater((key, data) => {
            this.model.forEach((x) => {
              if (x.key === key) {
                for (key in data) {
                  if (data[key] !== undefined) {
                    x.value[key] = data[key];
                  }
                }
              }
            });
          }),
          $editPromise: (_, modalParams) => {
            modalParams.formData = new Map(modalParams.formData)
              .map((f) => f.value)
              .toJS();
            return $q.resolve(modalParams);
          },
          $loading: false,
        });

        const toColumns = (fields, summary = {}) =>
          fields
            // Add to column only if a formatting is defined
            .filter(({ type }) => $filter('tableFmt')(null, type))
            .filter((field) => summary.has(field.key) || !summary.size)
            .map(({ key, templateOptions: { label } }) => ({
              key,
              name: summary[key] || label,
            }));

        const to = this.options.templateOptions;
        const { valueSchema, summaryFields } = to.valueType.templateOptions;
        this.title = to.label;
        this.schema = new Map(valueSchema.map((f) => [f.key, f]));
        this._model = mkModel(this.model);
        this._schema = { fields: valueSchema };
        this.columns = toColumns(valueSchema, new Map(summaryFields));
        this.editTitle = `Edit item for ${this.title}`;
        this.init = true;
      }
    },
  ],
};
